<script setup lang="ts">
import type { Promotion } from "@/types";

const { open } = useTaoModals();
const { t } = useT();

const props = defineProps<{
	image: string;
	type: Promotion["type"];
	howWorks: Record<string, string | number>;
	coins: number;
	entries: number;
	available: boolean;
	coinsSum: number;
	entriesSum: number;
	friendsQualifies: number;
	friendsInvited: number;
	showClose?: boolean;
}>();
defineEmits<{ (event: "close"): void }>();

const isBackCard = ref(false);

const rewards = [
	{ id: 1, label: "Friends joined", value: props.friendsInvited },
	{ id: 2, label: "Friends qualified", value: props.friendsQualifies },
	{ id: 3, label: "TAO Coins", value: props.coinsSum },
	{ id: 4, label: "Secret Coins", value: props.entriesSum }
];
const handleHowItWorksModal = () => {
	open("LazyOModalPromoHowItWorks", { howWorks: props.howWorks, type: props.type });
};
</script>

<template>
	<AAnimationFlipCard :flip="isBackCard" class="flip-card">
		<template #front>
			<ACard
				:imgAttrs="{
					width: 188,
					height: 160,
					format: 'avif',
					loading: 'lazy',
					src: image
				}"
				width="328px"
				height="325px"
			>
				<template #default>
					<span
						v-if="howWorks.cardHowItWorks"
						class="btn-info"
						data-tid="invite-info-btn"
						@click.stop="handleHowItWorksModal"
					>
						<ASvg name="16/info" class="icon" />
					</span>
					<span v-if="showClose" class="close" data-tid="close-modal" @click.stop="$emit('close')">
						<NuxtIcon name="20/close" filled />
					</span>
				</template>
				<template #top>
					<AText class="title" type="base" :modifiers="['bold', 'uppercase']">{{ t("Invite friends") }}</AText>

					<AText class="sub-title" type="small" :modifiers="['bold', 'uppercase']">
						{{ t("And get reward for each one") }}
					</AText>
				</template>
				<template #bottom>
					<div class="box">
						<AText v-if="coins !== 0" type="h4" data-tid="promo-invite-coins" class="count coins">
							<ASvg name="12/coins" class="icon" />
							{{ numberFormat(coins) }}
						</AText>
						<AText v-if="entries !== 0" type="h8" data-tid="promo-invite-entries" class="count entries">
							<ASvg name="12/secret-coins" class="icon" />
							{{ numberFormat(entries) }}
							<AText type="xx-small" class="count-after" :modifiers="['normal']">
								{{ t("Free Secret Coins") }}
							</AText>
						</AText>
					</div>
					<AButton variant="primary" size="s" class="promo" data-tid="refer-friend-btn">
						<NuxtIcon name="20/copy" class="btn-icon" :filled="true" />
						{{ t("Copy link") }}
					</AButton>
					<AText
						v-if="available"
						as="div"
						type="x-small"
						:modifiers="['uppercase', 'link-underline', 'bold']"
						class="foot color-neutral"
						data-tid="view-rewards"
						@click.stop="isBackCard = !isBackCard"
						>{{ t("View my rewards") }}
					</AText>
				</template>
			</ACard>
		</template>
		<template #back>
			<div class="cart-back">
				<AText type="base" class="title" :modifiers="['bold', 'uppercase']" as="div">{{ t("Rewards") }}</AText>
				<AText class="list" as="div">
					<div
						v-for="reward in rewards"
						:key="reward.id"
						:data-tid="`reward-${reward.label.replace(/ /g, '-').toLowerCase()}`"
					>
						{{ t(reward.label) }} <b>{{ reward.value }}</b>
					</div>
				</AText>
				<AText type="small" class="sub-title" :modifiers="['uppercase']">
					{{ t("You will see your rewards after at least one friend qualify") }}
				</AText>
				<div class="foot">
					<AText
						as="div"
						type="base"
						:modifiers="['link', 'underline']"
						data-tid="view-rewards-back-btn"
						@click.stop="isBackCard = !isBackCard"
					>
						{{ t("Back") }}
					</AText>
				</div>
			</div>
		</template>
	</AAnimationFlipCard>
</template>

<style scoped lang="scss">
.flip-card {
	width: 330px;
	height: 310px;
	max-width: 100%;

	&:deep(.front),
	&:deep(.back) {
		color: var(--neutral);
		background: var(--gradient-5);
		border-radius: var(--a-card-default-radius);
	}
}

.cart-back {
	padding: 16px;
	display: flex;
	flex-direction: column;
	gap: 24px;
	height: 100%;

	.sub-title {
		display: block;
	}

	.list {
		display: flex;
		flex-direction: column;
		gap: 16px;

		& > div {
			display: flex;
			justify-content: space-between;
		}
	}
}
</style>
